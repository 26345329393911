import HeroSection from "../HeroSection";
import Features from "../Features";
import Control from "../Control";
import Process from "../Process";
import HappyClients from "../HappyClients";
import Proud from "../Proud";
import Client from "../Client";
import About from "../About";
import Contact from "../Contact";
import Community from "../Community";
import InitialPopup from "../InitialPopup";

export const Home = () => {
    return (
        <div>
            {/*<InitialPopup/>*/}
            <HeroSection/>
            <Features/>
            <Control/>
            <Process/>
            <HappyClients/>
            <Proud/>
            <Client/>
            <About/>
            <Contact/>
            <Community/>
        </div>
    )
}
