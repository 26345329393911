import React from 'react'
import {Col} from "react-bootstrap";
import './CompanyHire.css'

const CompanyHire = ({formHire, setFormHire, toggle}) => {
    return (
        <Col sm={12} md={12} className="d-flex">
            <form className="form-container animated-hire">
                <div className="d-flex align-items-center gap-2 ">
                    <span className="heading-item block">4)</span>
                    <h2 className="mb-0">What type of hire do you need? <span>*</span></h2>
                </div>

                <p className="ms-4">We offer both Remote and On-Site Talent</p>
                <div className="input-group mb-0">
                    <ul className="ps-2 mb-0 d-flex align-items-start flex-column ">
                        <div>
                            <li className="list-item">
                                <input type="radio" id="on-site" name="on-site" value="on-site"
                                       checked={formHire.onSite === 'on-site'} onChange={(e) => {
                                    setFormHire({onSite: e.target.value})
                                }}/>
                                <label htmlFor="on-site" className="d-flex align-items-center gap-2 ">
                                    <span className="label-option">A</span>On-Site
                                </label>
                            </li>
                            <li className="list-item">
                                <input type="radio" id="remote" name="remote" value="remote"
                                       checked={formHire.remote === 'remote'} onChange={(e) => {
                                    setFormHire({remote: e.target.value})
                                }}

                                />
                                <label htmlFor="remote" className="d-flex align-items-center gap-2 ">
                                    <span className="label-option">B</span>Remote
                                </label>
                            </li>
                        </div>
                    </ul>
                </div>
                <div className="position-relative w-100">
                    {toggle.checkName?
                        <span className="position-absolute w-100 valid-error-sm">Please select one option.</span> : ""}
                </div>
            </form>
        </Col>
    )
}

export default CompanyHire
