import React from 'react'
import {Col} from "react-bootstrap";
import './skill.css'

const Skill = ({skill, setSkill, toggle}) => {
    return (
        <Col md={12} className="d-flex">
            <form className="form-container animated-skill">
                <div className="d-flex align-items-center gap-2 ">
                    <span className="heading-item">7)</span>
                    <h2 className="mb-0"> What skills should they have? <span>*</span></h2>
                </div>
                <div className="input-group mb-2">
                    <ul className="ps-2 mb-0">
                        <li className="list-item">
                            <input type="checkbox" id="ror" name="ror" value="ror"
                                   checked={skill.ror === 'ror'} onChange={(e) => {
                                setSkill((prevState) => ({...prevState, ror: !prevState.ror && e.target.value}))
                            }}/>
                            <label htmlFor="ror" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">A</span>Ruby on Rails
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="checkbox" id="python" name="python" value="python"
                                   checked={skill.python === 'python'} onChange={(e) => {
                                setSkill((prevState) => ({...prevState, python: !prevState.python && e.target.value}))
                            }}

                            />
                            <label htmlFor="python" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">B</span>Python
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="checkbox" id="node" name="node" value="node"
                                   checked={skill.node === 'node'} onChange={(e) => {
                                setSkill((prevState) => ({...prevState, node: !prevState.node && e.target.value}))
                            }}

                            />
                            <label htmlFor="node" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">C</span>Node JS
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="checkbox" id="react" name="react" value="react"
                                   checked={skill.react === 'react'} onChange={(e) => {
                                setSkill((prevState) => ({...prevState, react: !prevState.react && e.target.value}))
                            }}

                            />
                            <label htmlFor="react" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">D</span>React JS
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="checkbox" id="php" name="php" value="php"
                                   checked={skill.php === 'php'} onChange={(e) => {
                                setSkill((prevState) => ({...prevState, php: !prevState.php && e.target.value}))
                            }}

                            />
                            <label htmlFor="php" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">E</span>PHP
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="checkbox" id="javascript" name="javascript" value="javascript"
                                   checked={skill.javascript === 'javascript'} onChange={(e) => {
                                setSkill((prevState) => ({
                                    ...prevState,
                                    javascript: !prevState.javascript && e.target.value
                                }))

                            }}

                            />
                            <label htmlFor="javascript" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">F</span>JavaScript
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="checkbox" id="reactNative" name="reactNative" value="reactNative"
                                   checked={skill.reactNative === 'reactNative'} onChange={(e) => {
                                setSkill((prevState) => ({
                                    ...prevState,
                                    reactNative: !prevState.reactNative && e.target.value
                                }))

                            }}

                            />
                            <label htmlFor="reactNative" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">G</span>React Native
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="checkbox" id="ios" name="ios" value="ios"
                                   checked={skill.ios === 'ios'} onChange={(e) => {
                                setSkill((prevState) => ({...prevState, ios: !prevState.ios && e.target.value}))

                            }}

                            />
                            <label htmlFor="ios" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">H</span>IOS
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="checkbox" id="android" name="android" value="android"
                                   checked={skill.android === 'android'} onChange={(e) => {
                                setSkill((prevState) => ({...prevState, android: !prevState.android && e.target.value}))
                            }}

                            />
                            <label htmlFor="android" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">I</span>Android
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="checkbox" id="ui" name="ui" value="ui"
                                   checked={skill.ui === 'ui'} onChange={(e) => {
                                setSkill((prevState) => ({...prevState, ui: !prevState.ui && e.target.value}))

                            }}

                            />
                            <label htmlFor="ui" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">I</span>UI/UX
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="checkbox" id="otherSkill" name="otherSkill" value="otherSkill"
                                   checked={skill.otherSkill === 'otherSkill'} onChange={(e) => {
                                setSkill((prevState) => ({
                                    ...prevState,
                                    otherSkill: !prevState.otherSkill && e.target.value
                                }))

                            }}

                            />
                            <label htmlFor="otherSkill" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">J</span>Other
                            </label>
                        </li>
                    </ul>
                </div>
                <div className="position-relative w-100">
                    {toggle.checkName ? <span className=" w-100 position-absolute valid-error-sm">Please select at least one or multiple option.</span> : ""}
                </div>
            </form>
        </Col>
    )
}

export default Skill
