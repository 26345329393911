import React from "react";
import Tick from "../../../assets/img/icons/solid/tick.svg";
import "./thank.css"
import {Button} from "react-bootstrap";

const Thank = ({closeModal}) => {
    return (
        <div className="text-center d-flex align-items-center justify-content-center flex-column w-100 gap-2">
            <div className="thanks">
                <img src={Tick} alt="tick"/>
            </div>
            <h6 className="thank-title">Thanks You </h6>
            <p className="thank-sub-title ">We Received Your Request</p>
            <p className="thanks-details ">Our team will review your submission, and we will be in touch with you shortly
                to discuss the next steps in the hiring process.
            </p>
            <Button className="button-next mt-4" onClick={() => closeModal(false)}>Close</Button>
        </div>
    )
}

export default Thank
