import React, {useEffect, useRef, useState} from 'react'
import './Navbar.css'
import {Row, Col} from 'react-bootstrap'
import navlogo from '../assets/img/logo-dark.png'
import facebook from '../assets/img/icons/solid/facebook.svg'
import instagram from '../assets/img/icons/solid/instagram.png'
import browser from '../assets/img/icons/solid/browser.png'
import arrow from  '../assets/img/icons/solid/arrow-right.svg'
import hamburger from '../assets/img/icons/solid/hamburger-menu.svg'
import NavbarMobile from './NavbarMobile';
import {Link} from "react-scroll";
import {useLocation, useSearchParams} from "react-router-dom";
import {Link as ReactLink} from "react-router-dom";
import FormModal from "./FormModal/FormModal";


const Navbar = () => {
    const [searchParams] = useSearchParams()
    const currentUrl = searchParams.get("redirect")
    const {pathname} = useLocation();
    const hireTopTalent = " https://southville.typeform.com/talent-request";
    let menuref = useRef();
    let hamburgerref = useRef();

    const [isOpen, setOpen] = useState(false);
    const [navbarshow, setnavbarshow] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const opennavbar = () => {
        setOpen(true);
    }

    const openModalForm = () => {
        setOpenModal(prevModal => !prevModal)
    }
    const local = localStorage.getItem("qrForm");
    useEffect(()=>{
        const openModal = !!currentUrl;
        const openNow = local !== "1" && openModal;
        setOpenModal(openNow)
    }, [local])

    // useEffect(() => {
    //     document.addEventListener('mousedown', (e) => {
    //         if (!menuref.current.contains(e.target) && !hamburgerref.current.contains(e.target)) {
    //             setOpen(false);
    //         }
    //     });
    // })
    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);

        return () => {
            window.removeEventListener('scroll', stickNavbar);
        };
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            setnavbarshow(windowHeight);
        }
    };
    return (
        <>
            {isOpen ? <NavbarMobile menuref={menuref} isOpen={isOpen} setOpen={setOpen} onOpenModal={openModalForm}/> : " "}

            <section className={navbarshow > 450 ? 'navbaris animated fadeInDown' : ""}>
                <div className='nav-container'>
                    <nav className='d-none d-lg-block '>
                        <Row className='navbar-main d-flex align-items-center'>
                            <Col md={2}>
                                <div className='nav-logo'>
                                    <a href="/"><img src={navlogo} alt="navbar logo" style={{width: "150px"}}/></a>
                                </div>
                            </Col>
                            <Col md={7}>
                                {
                                    pathname === "/" ?
                                        <ul className='list-unstyled d-flex justify-content-around mb-0 align-items-center'>
                                            <li>
                                                <Link className="nav-links" activeClass="active" spy={true}
                                                      to="hersec-id"
                                                      offset={-100}>
                                                    Home
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-links" activeClass="active" spy={true}
                                                      to="features"
                                                      offset={-100}>
                                                    Features
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-links" activeClass="active" spy={true} to="process"
                                                      offset={-100}>
                                                    Process
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-links" activeClass="active" spy={true} to="clients"
                                                      offset={-100}>
                                                    Clients
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-links" activeClass="active" spy={true}
                                                      to="for-talent"
                                                      offset={-100}>
                                                    For Talent
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-links" activeClass="active" spy={true}
                                                      to="about-us"
                                                      offset={-100}>
                                                    About Us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-links" activeClass="active" spy={true}
                                                      to="contact-us"
                                                      offset={-100}>
                                                    Contact Us
                                                </Link>
                                            </li>
                                            <li>
                                                <ReactLink  className="nav-links" activeClass="active" spy={true} to="/gitex">
                                                    Gitex Dubai
                                                </ReactLink>
                                            </li>
                                        </ul> : <></>}
                            </Col>
                            <Col md={3}>
                                <>
                                    <div className='d-flex justify-content-center nav-social-main p-1'>
                                        <a className="text-decoration-none" href="#" rel="noreferrer">
                                            <button
                                                className=" py-1 hire-buton primary rounded-pill d-flex align-items-center border-0 justify-content-end btn-primary"
                                                onClick={openModalForm}>
                                                <span className="px-3 fw-bold">Hire Top Talent</span>
                                                <p><span className="circle"> <img className="arrow" src={arrow} alt=""
                                                                                  width='10'/> </span></p>
                                            </button>
                                        </a>
                                    </div>

                                    {/*<a href={"https://www.facebook.com/southvillesolutions/"} target={"_blank"} rel="noreferrer">*/}
                                    {/*    <img className=' nav-social me-3' src={facebook} alt="" width='20'/>*/}
                                    {/*</a>*/}
                                    {/*<a href={"mailto:info@southvillesol.com"} target={"_blank"} rel="noreferrer">*/}
                                    {/*    <img className=' nav-social me-3' src={browser} alt="" width='20'/>*/}
                                    {/*</a>*/}
                                    {/*<a href={"https://www.linkedin.com/company/southville-solutions/"}*/}
                                    {/*   target={"_blank"} rel="noreferrer">*/}
                                    {/*    <img className=' nav-social me-3' src={instagram} alt="" width='20'/>*/}
                                    {/*</a>*/}
                                    {/*</div>*/}
                                </>
                            </Col>
                        </Row>
                    </nav>
                    {/* Mobile Responsiveness */}
                    <nav className='d-lg-none d-block'>
                        <div className='py-3 navbar-main d-flex row'>
                            <div className='d-flex align-items-center col-4'>
                                <a href="/"><img className='nav-logo' src={navlogo} alt="logo"/></a>
                            </div>
                            <div className='d-flex justify-content-end px-2 col-8'>
                                <a href={"https://www.facebook.com/southvillesolutions/"} target={"_blank"}
                                   rel="noreferrer"
                                   style={{'marginTop': '10px'}}>
                                    <img className=' nav-social me-3' src={facebook} alt="" width='20'/>
                                </a>
                                <a href={"mailto:info@southvillesol.com"} target={"_blank"} rel="noreferrer"
                                   style={{'marginTop': '11px'}}>
                                    <img className=' nav-social me-3' src={browser} alt="" width='20'/>
                                </a>
                                <a href={"https://www.linkedin.com/company/southville-solutions/"} target={"_blank"}
                                   rel="noreferrer"
                                   style={{'marginTop': '9px'}}>
                                    <img className=' nav-social me-3' src={instagram} alt="" width='20'/>
                                </a>
                                <img ref={hamburgerref} onClick={opennavbar} className='me-3' src={hamburger} alt=""
                                     width='30'/>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>
            {openModal && <FormModal open={openModal} openFun={openModalForm}/>}
        </>

    )
}

export default Navbar
