import React, {useEffect, useState} from 'react'
import './FormModal.css'
import {Modal, Button} from 'react-bootstrap';
import {Row} from 'react-bootstrap'
import CompanyName from "./CompanyName/CompanyName";
import CompanyEmail from "./CompanyEmail/CompanyEmail";
import CompanyHire from "./CompanyHire/CompanyHire";
import FormName from "./FormName/FormName";
import CategoryRole from "./CategoryRole/CategoryRole";
import SoftwareEngineer from "./SoftwareEngineer/SoftwareEngineer";
import Skill from "./Skills/Skill";
import Anything from "./Anything/Anything";
import postModalData from "../../utils/data"
import Start from "./Start/Start";
import {useSearchParams} from "react-router-dom";
import Thank from "./Thank/Thank";

const FormModal = ({open, openFun}) => {
    const [searchParam] = useSearchParams();
    const checkIsQr = searchParam.get("redirect");
    const [showError, setShowError] = useState({
        checkName: false,
        checkEmail: false,
    })
    const [page, setPage] = useState(0);

    const [formData, setFormData] = useState({
        name: "",
        company: "",
        companyEmail: "",
        anything: "",
    });

    const [formHire, setFormHire] = useState({
        onSite: "",
        remote: "",
    })
    const [companyStack, setCompanyStack] = useState({
        backEnd: "",
        frontEnd: "",
        fullStack: "",
        devOps: "",
        dataEngineer: "",
        mobileDeveloper: "",
        productManager: "",
        technicalLead: "",
        otherStack: "",
    });
    const [range, setRange] = useState({
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",

    });
    const [skill, setSkill] = useState({
        ror: "",
        python: "",
        node: "",
        react: "",
        php: "",
        javascript: "",
        reactNative: "",
        ios: "",
        android: "",
        ui: "",
        otherSkill: "",
    });
    const previousPage = () => {
        setPage(prevState => prevState - 1)
    }

    function validateArray(data) {
        for (let key in data) {
            if (!data[key]) {
                return false;
            }
        }
        return true;
    }

    const components = [
        <Start/>,
        <FormName formData={formData} setFormData={setFormData} toggle={showError}/>,
        <CompanyName formData={formData} setFormData={setFormData} toggle={showError}/>,
        <CompanyEmail formData={formData} setFormData={setFormData} toggle={showError}/>,
        <CompanyHire formHire={formHire} setFormHire={setFormHire} toggle={showError}/>,
        <CategoryRole companyStack={companyStack} setCompanyStack={setCompanyStack} toggle={showError}/>,
        <SoftwareEngineer range={range} setRange={setRange} toggle={showError}/>,
        <Skill skill={skill} setSkill={setSkill} toggle={showError}/>,
        <Anything formData={formData} setFormData={setFormData}/>,
        <Thank closeModal={openFun}/>
    ];
    const nextButton = () => {
        const emailSubscribePatter = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (page === 1 && formData.name === "" || page === 2 && formData.company === "" || page === 3 && formData.companyEmail === "") {
            setShowError({checkName: true})
            return;
        } else if (page === 3 && (!emailSubscribePatter.test(formData.companyEmail))) {
            setShowError({checkEmail: true})
            return;
        } else {
            setShowError({checkEmail: false, checkName: false})
        }
        if (page === 4 || page === 5 || page === 6) {
            if (page === 4) if (!validateArray(formHire)) {
                setShowError({checkName: true})
                return;
            }
            if (page === 5) if (!validateArray(companyStack)) {
                setShowError({checkName: true})
                return;
            }
            if (page === 6) if (!validateArray(range)) {
                setShowError({checkName: true})
                return;
            }
        } else {
            setShowError({checkName: false})
        }
        if (page === components.length - 3) {
            const allSkillsEmpty = Object.values(skill).every(val => val === "");
            if (allSkillsEmpty) {
                setShowError({checkName: true})
                return;
            } else {
                setShowError({checkName: false})
            }
        }
        if (page === 8) postModalData(formData, formHire, companyStack, range, skill, checkIsQr)
        page + 1 === components.length ? openFun(!open) : setPage((currentPage) => currentPage + 1)
    }
    useEffect(() => {
        function callBack(event) {
            if (event.code === "Enter") event.preventDefault();
            if (event.code === "Escape") openFun(false)
        }

        document.addEventListener("keydown", callBack);
        return (() => {
            document.removeEventListener("keydown", callBack);
        })
    }, [])

    return (
        <Modal show={open} size="xl">
            <div className="modalContainer position-relative">
                <Row>
                    <div className="position-relative">
                        <Button className="p-2 position-absolute close-button d-flex align-items-center"
                                onClick={openFun}>x</Button>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <span> {page + 1}/10</span>
                    </div>
                    <div className="d-flex formModal">{components[page]}</div>
                    {page !== components.length - 1 &&
                        <div className=" d-flex align-items-center justify-content-end gap-5">
                            {page === 0 ? "" :
                                <Button className=" button-prev d-flex align-self-end" onClick={previousPage}
                                >Previous</Button>}
                            <Button className="mt-auto d-flex align-self-end button-next"
                                    onClick={nextButton}>
                                {page === 0 && "Start"}
                                {page > 0 && page <= (components.length - 3) && "Next"}
                                {page === components.length - 2 && "Submit"}
                            </Button>
                        </div>
                    }
                </Row>
            </div>
        </Modal>
    )
}
export default FormModal