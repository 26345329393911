import React from 'react'
import {Col} from "react-bootstrap";
import './SoftwareEngineer.css'

const SoftwareEngineer = ({range, setRange, toggle}) => {
    return (
        <Col sm={12} md={12} className="d-flex">
            <form className="form-container animated-software">
                <div className="d-flex align-items-center gap-2 ">
                    <span className="heading-item">6)</span>
                    <h2 className="mb-0"> How many software engineers are you looking to hire? <span>*</span></h2>
                </div>

                <p className="ms-4 d-none d-sm-block">We have the Talent ready for you!</p>
                <div className="input-group mb-2">
                    <ul className="ps-2">
                        <li className="list-item">
                            <input type="radio" id="1-2" name="1-2" value="1-2"
                                   checked={range.one === '1-2'} onChange={(e) => {
                                setRange({one: e.target.value})
                            }}/>
                            <label htmlFor="1-2" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">A</span>1-2
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="2-5" name="2-5" value="2-5"
                                   checked={range.two === '2-5'} onChange={(e) => {
                                setRange({two: e.target.value})
                            }}

                            />
                            <label htmlFor="2-5" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">B</span>2-5
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="5-8" name="5-8" value="5-8"
                                   checked={range.three === '5-8'} onChange={(e) => {
                                setRange({three: e.target.value})
                            }}

                            />
                            <label htmlFor="5-8" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">C</span>5-8
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="8-10" name="8-10" value="8-10"
                                   checked={range.four === '8-10'} onChange={(e) => {
                                setRange({four: e.target.value})
                            }}

                            />
                            <label htmlFor="8-10" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">D</span>8-10
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="10+" name="10+" value="10+"
                                   checked={range.five === '10+'} onChange={(e) => {
                                setRange({five: e.target.value})
                            }}

                            />
                            <label htmlFor="10+" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">E</span>10+
                            </label>
                        </li>
                    </ul>
                </div>
                <div className="position-relative w-100">
                    {toggle.checkName ? <span className=" w-100 position-absolute valid-error-sm">Please select one option.</span> : ""}
                </div>
            </form>
        </Col>
    )
}

export default SoftwareEngineer
