import React from 'react'
import {Col} from "react-bootstrap";
import './CategoryRole.css'

const CategoryRole = ({companyStack, setCompanyStack, toggle}) => {
    return (
        <Col sm={12} md={12} className="d-flex">
            <form className="form-container animated-role">
                <div className="d-flex align-items-center gap-2 ">
                    <span className="heading-item">5)</span>
                    <h2 className="mb-0">What is the category of the Role? <span>*</span></h2>
                </div>
                <div className="input-group mb-2">
                    <ul className="ps-2 mb-0 ">
                        <li className="list-item">
                            <input type="radio" id="backend-engineer" name="backend-engineer" value="backend-engineer"
                                   checked={companyStack.backEnd === 'backend-engineer'} onChange={(e) => {
                                setCompanyStack({backEnd: e.target.value})
                            }}/>
                            <label htmlFor="backend-engineer" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">A</span>Backend Engineer
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="frontend-engineer" name="frontend-engineer"
                                   value="frontend-engineer"
                                   checked={companyStack.frontEnd === 'frontend-engineer'} onChange={(e) => {
                                setCompanyStack({frontEnd: e.target.value})
                            }}

                            />
                            <label htmlFor="frontend-engineer" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">B</span>Frontend Engineer
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="fullstack-engineer" name="fullstack-engineer"
                                   value="fullstack-engineer"
                                   checked={companyStack.fullStack === 'fullstack-engineer'} onChange={(e) => {
                                setCompanyStack({fullStack: e.target.value})
                            }}/>
                            <label htmlFor="fullstack-engineer" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">C</span>Fullstack Engineer
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="devOps-engineer" name="devOps-engineer" value="devOps-engineer"
                                   checked={companyStack.devOps === 'devOps-engineer'} onChange={(e) => {
                                setCompanyStack({devOps: e.target.value})
                            }}

                            />
                            <label htmlFor="devOps-engineer" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">D</span>DevOps Engineer
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="data-engineer" name="data-engineer" value="data-engineer"
                                   checked={companyStack.dataEngineer === 'data-engineer'} onChange={(e) => {
                                setCompanyStack({dataEngineer: e.target.value})
                            }}

                            />
                            <label htmlFor="data-engineer" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">E</span>Data Engineer
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="mobile-engineer" name="mobile-engineer" value="mobile-engineer"
                                   checked={companyStack.mobileDeveloper === 'mobile-engineer'} onChange={(e) => {
                                setCompanyStack({mobileDeveloper: e.target.value})
                            }}/>
                            <label htmlFor="mobile-engineer" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">F</span>Mobile Engineer
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="product-manager" name="product-manager" value="product-manager"
                                   checked={companyStack.productManager === 'product-manager'} onChange={(e) => {
                                setCompanyStack({productManager: e.target.value})
                            }}/>
                            <label htmlFor="product-manager" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">G</span>Product Manager
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="technical-lead" name="technical-lead" value="technical-lead"
                                   checked={companyStack.technicalLead === 'technical-lead'} onChange={(e) => {
                                setCompanyStack({technicalLead: e.target.value})
                            }}

                            />
                            <label htmlFor="technical-lead" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">H</span>Technical Lead
                            </label>
                        </li>
                        <li className="list-item">
                            <input type="radio" id="otherStack" name="otherStack" value="otherStack"
                                   checked={companyStack.otherStack === 'otherStack'} onChange={(e) => {
                                setCompanyStack({otherStack: e.target.value})
                            }}/>
                            <label htmlFor="otherStack" className="d-flex align-items-center gap-2 ">
                                <span className="label-option">I</span>Other
                            </label>
                        </li>
                    </ul>
                </div>
                <div className="position-relative w-100">
                    {toggle.checkName ? <span className=" w-100 position-absolute valid-error-sm">Please select one option.</span> : ""}
                </div>
            </form>
        </Col>
    )
}

export default CategoryRole
