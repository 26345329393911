import {Col, Container, Row} from "react-bootstrap";
import './term.css'
import pathImg from '../../assets/img/link_icon.svg'
import {Link} from 'react-scroll'
import React from "react";


export const Term = () => {
    return (
        <>
            <section id="term" className={'term-section'}>
                <div className="term-header d-flex flex-column align-items-center justify-content-center">
                    <p className='display-1'>Terms and Conditions</p>
                    <div className="list-unstyled d-flex align-items-center justify-content-center">
                        <li><a className="text-decoration-none me-2" href="/">Home</a></li>
                        <img className="me-2" src={pathImg} alt="link icon" width='25px'/>
                        <li>Terms and Conditions</li>
                    </div>
                </div>
                <Container>
                    <div className='term-inner-sec mt-5'>
                        <Row>
                            <Col lg={3} className="mt-md-0 d-lg-block d-none p-0 term-aside">
                                <ul className="p-0 list-unstyled term-link">
                                    <li>
                                        <Link className="nav-link scroll" activeClass="active" spy={true}
                                              to="terms-conditions"
                                              offset={-100}>
                                            1.
                                            Terms &amp; Conditions
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link scroll" activeClass="active" spy={true}
                                              to="privacy-policy"
                                              offset={-100}>
                                            2. Privacy Policy
                                        </Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col lg={8} className='text-center'>
                                <Row className="term-sm-container">
                                    <div className="term-card element p-4 mb-5" id="terms-conditions">
                                        <h1>Terms and Conditions</h1>
                                        <h2 className="text-start my-3">1. Introduction</h2>
                                        <p className="text-start mb-3">Welcome to Southville Solutions (“Company”, “we”, “our”, “us”)!
                                            These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website located at https://southvillesol.com/ (together or individually “Service”) operated by Southville Solutions.
                                            Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages.
                                        </p>
                                        <p className="text-start mb-3">
                                            Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements and agree to be bound of them.
                                        </p>
                                        <p className="text-start mb-3">
                                            If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at info@southvillesol.com so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.
                                        </p>
                                        <h2 className="text-start my-3">2. Communications</h2>
                                        <p className="text-start mb-3">
                                            By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at info@southvillesol.com.
                                        </p>
                                        <h2 className="text-start my-3">3. Contests, Sweepstakes and Promotions</h2>
                                        <p className="text-start mb-3">
                                            Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through Service may be governed by rules that are separate from these Terms of Service. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will apply.
                                        </p>
                                        <h2 className="text-start my-3">4. Content</h2>
                                        <p className="text-start mb-3">
                                            Content found on or through this Service are the property of Southville Solutions or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.
                                        </p>
                                        <h2 className="text-start my-3">4. Prohibited Uses</h2>
                                        <p className="text-start mb-3">
                                            You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:
                                        </p>
                                        <div className='mb-3 '>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>In any way that violates any applicable national or international law or regulation.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</p>
                                            </div>
                                        </div>
                                        <p className="text-start mb-3">
                                            Additionally, you agree not to:
                                        </p>
                                        <div className='mb-3 '>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Use any device, software, or routine that interferes with the proper working of Service.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Take any action that may damage or falsify Company rating.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Otherwise attempt to interfere with the proper working of Service.</p>
                                            </div>
                                        </div>
                                        <h2 className="text-start my-3">6. Analytics</h2>
                                        <p className="text-start mb-3">
                                            We may use third-party Service Providers to monitor and analyze the use of our Service.
                                        </p>

                                        <h2 className="text-start my-3">7. Intellectual Property</h2>
                                        <p className="text-start mb-3">
                                            Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Southville Solutions and its licensors. Service is protected by copyright, trademark, and other laws of and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of Southville Solutions.
                                        </p>

                                        <h2 className="text-start my-3">8. Copyright Policy</h2>
                                        <p className="text-start mb-3">
                                            We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.
                                        </p>

                                        <p className="text-start mb-3">
                                            If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to info@southvillesol.com, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”
                                        </p>

                                        <p className="text-start mb-3">
                                            You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.
                                        </p>
                                    </div>
                                    <div className="term-card element p-4 mb-5" id="privacy-policy">
                                        <h1>Privacy Policy</h1>
                                        <p className="text-start mb-3">At Southville Solution, accessible from <a target={"_blank"}  rel={"noreferrer"} href={"https://www.southvillesol.com/"}>www.southvillesol.com</a>, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Southville and how we use it.</p>
                                        <p className="text-start mb-3">If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
                                        <p className="text-start mb-3">This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Southville. This policy is not applicable to any information collected offline or via channels other than this website.</p>
                                        <h2 className="text-start my-3">Consent</h2>
                                        <p className="text-start mb-3">
                                            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                                        </p>
                                        <h2 className="text-start my-3">Information we collect</h2>
                                        <p className="text-start mb-3">
                                            The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                                        </p>

                                        <p className="text-start mb-3">
                                            If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                                        </p>

                                        <p className="text-start mb-3">
                                            Further, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number that to be kept confidential.
                                        </p>
                                        <h2 className="text-start my-3">How we use your information</h2>
                                        <p className="text-start mb-3">
                                            We use the information we collect in various ways, including to:
                                        </p>
                                        <div className='mb-3 '>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Provide, operate, and maintain our website</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Improve, personalize, and expand our website.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Understand and analyze how you use our website.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Develop new products, services, features, and functionality.</p>
                                            </div>
                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.</p>
                                            </div>

                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Send you emails.</p>
                                            </div>

                                            <div className='d-flex align-items-baseline mb-1 '>
                                        <span
                                            className='term-icon rounded-circle px-md-2 me-2 d-flex align-items-center justify-content-center'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                                <p className='text-start'>Find and prevent fraud.</p>
                                            </div>
                                        </div>
                                        <h2 className="text-start my-3">Log Files</h2>
                                        <p className="text-start mb-3">
                                            Southville follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
                                        </p>

                                        <h2 className="text-start my-3">Advertising Partners Privacy Policies</h2>
                                        <p className="text-start mb-3">
                                            You may consult this list to find the Privacy Policy for each of the advertising partners of Southville.
                                        </p>
                                        <p className="text-start mb-3">
                                            Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Southville, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                                        </p>

                                        <p className="text-start mb-3">
                                            Note that Southville has no access to or control over these cookies that are used by third-party advertisers.
                                        </p>
                                        <h2 className="text-start my-3">Third Party Privacy Policies</h2>
                                        <p className="text-start mb-3">
                                            Southville's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                                        </p>
                                        <p className="text-start mb-3">
                                            You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
                                        </p>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}
