import React from 'react'
import './Features.css'
import { Row, Col, Container } from 'react-bootstrap'
import script from '../assets/img/icons/solid/script.png'
import verify from '../assets/img/icons/solid/verify.png'
import network from '../assets/img/icons/solid/cloud-network.png'
import director from '../assets/img/icons/solid/director.png'
import setting from '../assets/img/icons/solid/setting.png'
import server from '../assets/img/icons/solid/server.png'

function Features() {
    return (
        <>
            <section className='feature-main-sec bg-light' id='features'>
                <Container>
                    <div className='main-heading text-center px-3' >
                    <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">Our Features</h2>
                        <h3 className="display-4 mb-9 f-head-main">Effortless Recruitment and Selection</h3>
                    </div>
                    <div>
                        <Row className='px-1'>
                            <Col md={6} lg={4} className='d-flex mt-2 mb-2'>
                                <div className='pe-3 mt-2' >
                                    <img src={script} className="f-script" alt="" />
                                </div>
                                <div className='mt-2 mb-1'>
                                    <h3 className="fs-22 ">Targeted Recruitment Strategy</h3>
                                    <p>We’re here to customize your Recruitment strategy with your Job opportunities. Highly potential candidates will be matched with your open positions.</p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className='d-flex mt-2 mb-2 '>
                                <div className='pe-3 mt-2' >
                                    <img src={verify} className="f-script" alt="" />
                                </div>
                                <div className='mt-2'>
                                    <h3 className="fs-22 ">Personalized Outreach</h3>
                                    <p>Personalized outreach is created to pair top talent with your Company. Southville focus on each Company according to its specific needs and demand.</p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className='d-flex mt-2 mb-2'>
                                <div className='pe-3 mt-2' >
                                    <img src={network} className="f-script" alt="" />
                                </div>
                                <div className='mt-2'>
                                    <h3 className="fs-22 ">Pre-Vetted Candidates</h3>
                                    <p>Hire pre-vetted candidates in minutes and save your additional time and effort. Make your hiring experience cost-effective and efficient.</p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className='d-flex mt-2 mb-4'>
                                <div className='pe-3' >
                                    <img src={director} className="f-script" alt="" />
                                </div>
                                <div>
                                    <h3 className="fs-22 ">Well Managed Processes</h3>
                                    <p>We’re here to manage risk-free and top-quality employment processes. The entire employment process will align with your Company’s laws and regulations.</p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className='d-flex mt-2 mb-4'>
                                <div className='pe-3' >
                                    <img src={setting} className="f-script" alt="" />
                                </div>
                                <div>
                                    <h3 className="fs-22 ">Hands on Support</h3>
                                    <p>A highly committed team is available for company's assistance, support, and guidance 24/7. Our collaborative team is only one message away from you.</p>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className='d-flex mt-2 mb-4'>
                                <div className='pe-3' >
                                    <img src={server} className="f-script" alt="" />
                                </div>
                                <div>
                                    <h3 className="fs-22 ">Top Remote Talent pool</h3>
                                    <p>Recruitment and selection from the global pool of candidates is no more a dream. Scale up your team by hiring skilled employees remotely. </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Features