import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/js/brands'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/fontawesome'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/fonts/urbanist.css'
import './assets/fonts/style.css'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import ScrolltoTop from './components/ScrolltoTop'
import './App.css'
import {Term} from "./components/Terms/Term";
import {Route, Routes} from "react-router-dom";
import {Home} from "./components/HomePage/Home";
import GiTex from "./components/GiTex/GiTex";

const App = () => {
   return (
      <div className="App bg-light">
          <Navbar />
          <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/privacy" element={<Term/>} />
              <Route path="/gitex" element={<GiTex/>}/>
          </Routes>
         <Footer />
         <ScrolltoTop />
      </div>
   );
}

export default App;
