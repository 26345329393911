import React from 'react'
import {Container, Col, Row} from 'react-bootstrap'
import Footerlogo from '../assets/img/logo.png'
import './Footer.css'
import browser from '../assets/img/icons/solid/browser.png'
import facebook from '../assets/img/icons/solid/facebook.svg'
import instagram from '../assets/img/icons/solid/instagram.png'
const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <>
            <section className='bg-light'>
                <Container className='footer-main-sec' id='footer-id'>
                    <Row>
                        <Col md={4} lg={3}>
                            <div>
                                <div className='nav-logo'>
                                    <a href="/"><img src={Footerlogo} alt="navbar logo" width="200"/></a>
                                </div>
                                <div className='mt-3'>
                                    <p className="mb-4">© Copyright {year} Southville Solutions.
                                        <br className="d-none d-lg-block">
                                        </br>
                                        All rights reserved.</p>

                                </div>
                                <div className='d-flex justify-content-start'>

                                    <a className="p-2 nav-social" href={"https://www.facebook.com/southvillesolutions/"} target={"_blank"} rel="noreferrer">
                                        <img src={facebook} alt="" width='20'/>
                                    </a>
                                    <a className="p-2 nav-social" href={"mailto:info@southvillesol.com"} target={"_blank"} rel="noreferrer">
                                        <img src={browser} alt="" width='20'/>
                                    </a>
                                    <a className="p-2 nav-social" href={"https://www.linkedin.com/company/southville-solutions/"}
                                       target={"_blank"} rel="noreferrer">
                                        <img  src={instagram} alt="" width='20'/>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} lg={3} className='footer-touch'>
                            <div>
                                <h4>Address</h4>
                                <address>85 Service Rd, Block R1 Phase 2 Johar Town, Lahore, Punjab 54000, Pakistan
                                </address>
                            </div>
                            <div>
                                <p className='mb-0 '><a className='text-decoration-none footer-email-link'
                                                        href="info@email.com">info@southvillesol.com</a></p>
                                <p>(+92) 309 000945</p>
                            </div>
                        </Col>
                        <Col md={4} lg={3}>
                            <div>
                                <h4>Learn More</h4>
                            </div>
                            <div>
                                <ul className='list-unstyled '>
                                    <li className=' mt-2 '><a className='footer-links footer-para' href="/#features">Features</a></li>
                                    <li className=' mt-2 '><a className='footer-links footer-para' href="/#process">Process</a></li>
                                    <li className=' mt-2 '><a className='footer-links footer-para' href="/#about-us">About Us</a></li>
                                    <li className=' mt-2 '><a className='footer-links footer-para' href="/privacy">Terms of Use</a></li>
                                    <li className=' mt-2 '><a className='footer-links footer-para' href="/privacy">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={12} lg={3}>
                            {/*<div>*/}
                            {/*    <h4>Our Newsletter</h4>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    <p>Subscribe to our newsletter to get our news*/}
                            {/*        & deals delivered to you.</p>*/}
                            {/*</div>*/}
                            {/*<div className='d-flex'>*/}
                            {/*    <div className="mc-field-group input-group form-floating">*/}
                            {/*        <input type="email" name="EMAIL" className="required email form-control" placeholder="Email Address" id="mce-EMAIL2" />*/}
                            {/*        <label>Email Address</label>*/}
                            {/*        <input type="submit" value="Join" name="subscribe" id="mc-embedded-subscribe2" className="btn btn-primary btn-gradient gradient-1 footer-btn-main" />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default Footer
