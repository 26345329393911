import React from 'react'
import './About.css'
import g11 from '../assets/img/photos/g11.jpg'
import { Container, Row, Col } from 'react-bootstrap'
const About = () => {
    return (
        <>
            <section id='about-id' className='bg-light'>
                <Container className='About-main'>
                    <Row>
                        <Col lg={6} className='d-md-none d-block' >
                            <div >
                                <div className='col-12 d-flex mt-3 mb-5 justify-content-center'>
                                    <img className='rounded mt-3' src={g11} alt="" width="100%" />
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className='d-none d-md-block' >
                            <div >
                                <div className='col-12 d-flex justify-content-center'>
                                    <img className='rounded mt-3' src={g11} alt="" width="90%" />
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className="about-main-sec-2" id="about-us">
                            <div>
                                <div>
                                    <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3"> Who Are We?</h2>
                                    <h3 className='display-4' >Company that believes in the power
                                        of creative strategy.</h3>
                                    <p>
                                        Southville Solutions is a team of devoted individuals who work relentlessly to provide world-class hiring services. Our professional team is committed to connect talented candidates with excellent opportunities regardless of their background. With us, your Company can:
                                    </p>
                                </div>
                                <div className='my-4 row '>
                                    <div className='col-lg-6 d-flex '>
                                        <span className='as-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check as-fa-check"></i>
                                        </span>
                                        <p>Focus on expanding your business.</p>
                                    </div>
                                    <div className='col-lg-6 d-flex '>
                                        <span className='as-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check as-fa-check"></i>
                                        </span>
                                        <p>Hire the perfect fit in less time.</p>
                                    </div>
                                    <div className='col-lg-6 d-flex '>
                                        <span className='as-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check as-fa-check"></i>
                                        </span>
                                        <p>Save hiring cost.</p>
                                    </div>
                                    <div className='col-lg-6 d-flex '>
                                        <span className='as-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check as-fa-check"></i>
                                        </span>
                                        <p>Engage with Asia's top talent.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <div className='col-lg-4 my-2'>
                            <div className='d-flex'>
                                <div className='about-icon d-flex me-2'>
                                    <span className='as-icon-1'>
                                        1
                                    </span>
                                </div>
                                <div className=''>
                                    <h4>Our Vision</h4>

                                    <div className='me-5 about-para'>Our vision is to simplify the recruitment and selection process by becoming the best talent partners remotely.</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 my-2'>
                            <div className='d-flex'>
                                <div className='about-icon d-flex me-2'>
                                    <span className='as-icon-1'>
                                        2
                                    </span>
                                </div>
                                <div className=''>
                                    <h4>Our Mission</h4>

                                    <div className='me-5 about-para'>To make companies successful and more productive by connecting Asia’s top remote talent globally.</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 my-2'>
                            <div className='d-flex'>
                                <div className='about-icon d-flex  me-2'>
                                    <span className='as-icon-1'>
                                        3
                                    </span>
                                </div>
                                <div className=''>
                                    <h4>Our Values</h4>

                                    <div className='me-5 about-para'>We believe in Ownership, Excellence, Productivity and Effective Communication.</div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default About
