import React from 'react'
import {Col} from "react-bootstrap";
import './FormName.css'

const FormName = ({formData, setFormData, toggle}) => {
    return (
        <Col sm={12} md={12} className="d-flex">
            <form className="form-container animated-name ">
                <h2> 1) What your name? <span>*</span></h2>
                <p className="ms-4">Nice to meet you!</p>

                <div className="position-relative w-100">
                    <input type="text" className="w-100 input-form" placeholder="Type your answer here..."
                           value={formData.name} onChange={(e) => setFormData({...formData, name: e.target.value})}/>
                    {toggle.checkName ? <span className="position-absolute valid-error">Name can't be empty.</span> : ""}
                </div>
            </form>
        </Col>
    )
}

export default FormName
