import React  from 'react'
import './HappyClint.css'
import HappyClientimg from '../assets/img/photos/about27.jpg'
import { Row, Col, Container } from 'react-bootstrap'
import developers from "../assets/img/illustrations/3d4.png";



const HappyClients = () => {
    return (
        <>
            <section className='happy-client-main-sec bg-light'>
                <Container>
                    <div className='d-lg-block d-none'>
                        <Row>
                            <Col md={6} className='hc-sec-1'>
                                <div>
                                    <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">What Makes Us Different?</h2>
                                    <h3 className='display-4'>Our Liability for your Stability</h3>
                                </div>
                                <div className='my-3 hc-para'>
                                    <p>
                                        Hiring candidates through Southville Solutions makes us liable for the Quality and Performance of the candidate. We are responsible for your entire recruitment process to save your resources. Our team ensures that the recruitment process must align with your requirements.
                                    </p>
                                </div>
                                <div>
                                    <div className='d-flex '>
                                        <span className='hs-icon me-2 text-center '>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'>Resourceful Time Management</p>
                                    </div>
                                    <div className='hs-check d-flex'>
                                        <span className='hs-icon me-2 text-center'>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'>Perfectly Cost-effective methodologies</p>
                                    </div>
                                    <div className='hs-check d-flex'>
                                        <span className='hs-icon me-2 text-center'>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'>Less effort, more growth</p>
                                    </div>

                                    <div className='d-flex '>
                                        <span className='hs-icon me-2 text-center '>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'>Top-quality Onboarding Process</p>
                                    </div>
                                    <div className='hs-check d-flex'>
                                        <span className='hs-icon me-2 text-center'>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'>Amazing Team Support</p>
                                    </div>
                                    <div className='hs-check d-flex'>
                                        <span className='hs-icon me-2 text-center'>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'> Managing your Companies Compliance</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className='d-flex '>
                                <img src={HappyClientimg} alt="cs-vector" className='cs-img-main' width='100%' height='100%' />
                            </Col>
                        </Row>
                    </div>
                    {/* mobile responsiveness */}
                    <div className='d-block d-lg-none' id="for-talent">
                        <Row>
                            <Col lg={6}>
                                <img src={HappyClientimg} alt="cs-vector" className='cs-img-main' width='100%' />
                            </Col>
                            <Col lg={6} className="py-5">
                                <div>
                                    <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">What Makes Us Different?</h2>
                                    <h3 className='display-4'>Our Liability for your Stability</h3>
                                </div>
                                <div className='hs-para my-3'>
                                    <p>
                                        Hiring candidates through Southville Solutions makes us liable for the Quality and Performance of the candidate. We are responsible for your entire recruitment process to save your resources. Our team ensures that the recruitment process must align with your requirements.
                                    </p>
                                </div>
                                <div>
                                    <div className='d-flex '>
                                        <span className='hs-icon me-2 text-center '>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'>Resourceful Time Management</p>
                                    </div>
                                    <div className='hs-check d-flex'>
                                        <span className='hs-icon me-2 text-center'>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'>Perfectly Cost-effective methodologies</p>
                                    </div>
                                    <div className='hs-check d-flex'>
                                        <span className='hs-icon me-2 text-center'>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'>Less effort, more growth</p>
                                    </div>
                                    <div className='d-flex '>
                                        <span className='hs-icon me-2 text-center '>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'>Top-quality Onboarding Process</p>
                                    </div>
                                    <div className='hs-check d-flex'>
                                        <span className='hs-icon me-2 text-center'>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'>Amazing Team Support</p>
                                    </div>
                                    <div className='hs-check d-flex'>
                                        <span className='hs-icon me-2 text-center'>
                                            <i className=" fa fa-check hs-fa-check"></i>
                                        </span>
                                        <p className='hc-icon-para'>Managing your Companies Compliance</p>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Container>
            </section>
            <section className='control-sec-main bg-light'>
                <Container>
                    <div>
                        <Row>
                            <Col lg={6}>
                                <div className='control-img'>
                                    <img src={developers} alt="cs-vector" width='100%' />
                                </div>
                            </Col>
                            <Col lg={6} className='mt-2 mb-5 control-sec-2'>
                                <div>
                                    <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">Looking for Remote Work?</h2>
                                    <h3 className='display-4 mb-4'>Work from Anywhere</h3>
                                    <p>We tend to be in the race and our team work effortlessly to provide comfortable and flexible remote jobs from all around the globe. We are focused on giving people exposure to work in diverse teams.</p>
                                </div>
                                <div className='my-4 row '>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>International Companies</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Select your own Rate</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Career Growth</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Long term contracts</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Take Ownership</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Get paid on time</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Work Remote</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Flexible Hours</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Transparent Processes</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Get expertly matched</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default HappyClients