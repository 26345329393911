import React, {useState} from 'react';
import "./GiTex.css";
import bannerside from "../../assets/img/g3.jpg"
import sideabout from "../../assets/img/g5.jpg"
import Aimingsuccess from "../../assets/img/aimingnew.jpg"
import overcoming from "../../assets/img/time-zonenew.jpg"
import Collaboration from "../../assets/img/collaborationnew.jpg"
import requitment from "../../assets/img/Recruitmentnew.jpg"
import officeimg from "../../assets/img/Back-officenew.jpg"
import Dtransformation from "../../assets/img/Digital-transformation-new2.jpg"
import facebookicon from "../../assets/img/facebookicon.svg"
import instagramicon from "../../assets/img/instagram-g.svg"
import linkedinicon from "../../assets/img/linkedin-g.svg"
import burgkhalifa from "../../assets/img/g1.jpg"
import khalifa2 from "../../assets/img/g2.jpg"
import khalifadown from "../../assets/img/khalifadown.jpg"
import enhanced from "../../assets/img/enhanced-visibility-N.jpg"
import productshowcase from "../../assets/img/product-showcase-N.jpg"
import insights from "../../assets/img/insights-and-trends-N.jpg"
import colabration from "../../assets/img/collaboration-opportunities-N.jpg"
import Talentacquisition from "../../assets/img/Talent-acquisition-N.jpg"
import globalexposure from "../../assets/img/global-exposure-N.jpg"
import competitive from "../../assets/img/competitive-edge-N.jpg"
import gites2nd from "../../assets/img/g6.jpg"
import h26 from "../../assets/img/h26.jpg"
import FormModal from "../FormModal/FormModal";

const GiTex = () => {
    const [openModal, setOpenModal] = useState(false);

    function handleToggle(e) {
        e.preventDefault();
        setOpenModal(open => !open);
    }

    return (
        <>
            <section className="hero-bg">
                <div className="giTex-container">
                    <div className="row d-flex align-items-center">
                        <div className="col-12">
                            <div className="hero-content">
                                <h1 className="display-2 fw-bold mb-3 text-white text-center">
                                    Meet us at <span className="text-strok">GITEX</span> for an incredibly exciting
                                    experience. Join us in the midst of all the buzz and fun that awaits.<br/>
                                    <span className="hero-date">FROM 16-20 October, 2023 AT</span>
                                    <u className="text-warning">
                                        <span className="animated-text mt-3">Dubai World Trade Centre</span>
                                    </u>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-32">
                <div className="giTex-container mx-auto text-center lg:text-left xl:px-32">
                    <div className="giTex-container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className="p-4 giant-explain">
                                    <h3 className="mb-4 text-start">GITEX – A giant tech event!</h3>
                                    <p className="mb-4 ">
                                        GITEX (Gulf Information Technology Exhibition) is one of the most prominent and
                                        significant technology events in the world. Held annually in Dubai, UAE, it
                                        serves as a hub for global technology enthusiasts, industry leaders, innovators,
                                        and businesses to converge and showcase their latest advancements.
                                    </p>
                                    <p className="mb-4 ">
                                        At GITEX, cutting-edge technologies and groundbreaking solutions take center
                                        stage, offering a glimpse into the future of various industries. It provides a
                                        unique platform for tech companies, startups, and entrepreneurs to demonstrate
                                        their products and services to a vast international audience.
                                    </p>
                                    <p className="mb-4 ">
                                        The event's importance lies in its ability to foster collaboration, networking,
                                        and knowledge exchange among industry professionals. It facilitates partnerships
                                        and business opportunities, opening doors for new ventures and growth
                                        possibilities.
                                    </p>
                                    <p className="mb-4 ">
                                        GITEX serves as a catalyst for digital transformation, inspiring organizations
                                        to embrace innovation and adapt to the rapidly evolving technological landscape.
                                        It showcases the latest trends in artificial intelligence, cybersecurity, cloud
                                        computing, blockchain, IoT, and more, empowering businesses to stay ahead in a
                                        competitive market.
                                    </p>
                                    <p className="mb-4">
                                        For attendees, GITEX is a treasure trove of insights and learning experiences
                                        through conferences, workshops, and expert-led sessions. It offers a chance to
                                        gain valuable knowledge from thought leaders, visionaries, and industry experts.
                                    </p>
                                    <p className="">
                                        In a world where technology continues to drive progress and change lives, GITEX
                                        plays a pivotal role in shaping the future of industries and economies. As the
                                        world moves towards a digitally interconnected era, GITEX stands as a beacon of
                                        innovation and a testament to the transformative power of technology.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="explain-img">
                                    <img src={gites2nd}
                                         className="w-100 g6" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section>
                <div className="giTex-container mx-auto">
                    <div className="row align-items-center gx-lg-5">
                        <div className="gitex-head-outer">
                            <h3 className="mb-1 gitex-head">Participating in GITEX offers:</h3>
                            <p className="subhead-offers text-center ">Participating in GITEX offers a multitude of
                                benefits:</p>
                        </div>
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="participating-card">
                                <p className="participating-heading">
                                    Enhanced Visibility
                                </p>
                                <p className= "card-outer" >
                                    Gain increased exposure and market presence, as GITEX draws a
                                    global audience of technology enthusiasts, investors, and potential customers.
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="participating-card">
                                <p className="participating-heading">
                                    Product Showcase
                                </p>
                                <p className= "card-outer">
                                    Showcase your latest innovations, conduct product launches, and deliver captivating demos to captivate the industry with your cutting-edge offerings.
                                </p>
                            </div>

                        </div>

                    </div>
                    <div className="row align-items-center gx-lg-5">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="participating-card">
                                <p className="participating-heading">
                                    Insights and Trends
                                </p>
                                <p className= "card-outer">
                                    Stay ahead of the competition with valuable industry insights and
                                    emerging technology trends shared by renowned experts and thought leaders.
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="participating-card">
                                <p className="participating-heading">
                                    Collaboration Opportunities
                                </p>
                                <p className= "card-outer">
                                    Forge valuable partnerships and collaborations with industry
                                    peers, opening doors to new business opportunities and synergies.
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="participating-card">
                                <p className="participating-heading">
                                    Talent Acquisition
                                </p>
                                <p className= "card-outer">
                                    Leverage the event's platform to attract top talent, ensuring
                                    access to a pool of skilled professionals keen on joining innovative
                                    organizations.
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="participating-card">
                                <p className="participating-heading">
                                    Global Exposure
                                </p>
                                <p className= "card-outer">
                                    Benefit from international reach and media exposure,
                                    positioning your brand on the global stage and expanding your customer base beyond
                                    borders.
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-6 mb-4 mb-lg-0 mx-auto">
                            <div className="participating-card">
                                <p className="participating-heading">
                                    Competitive Edge
                                </p>
                                <p className= "card-outer">
                                    Gain a competitive advantage by being part of
                                    this prestigious event, solidifying your credibility as a leader in the tech
                                    industry
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section id="about-section" className="pt-5 pb-5">
                <div className="giTex-container mx-auto wrapabout">
                    <div className="red"></div>
                    <div className="row">
                        <div className="col-lg-8 align-items-center justify-content-left d-flex mb-5 mb-lg-0 order-2">
                            <div className="blockabout">
                                <div className="blockabout-inner text-center text-sm-start">
                                    <div className=" pb-3 mb-1">
                                        <h3 className="title-south">Southville’s X GITEX 🚀</h3>
                                    </div>
                                    <p className="description-p  pe-0 pe-lg-0">
                                        Step into the future of software development and staff augmentation at <span
                                        className="text-strok min-strok">GITEX</span>
                                    </p>
                                    <p className="description-p pe-0 pe-lg-0">Southville Solutions, your trusted
                                        technology partner, presents game-changing solutions that will redefine your
                                        business. </p>
                                    <p>Experience the untapped potential of custom software development and access
                                        exceptional talent at our booth <span className="dev-slam"> H26-P04 in GLOBAL DEV SLAM.</span>
                                    </p>
                                    <p> Join us at GITEX to unlock a world of limitless possibilities and propel your
                                        business to new heights! Don't miss out on this transformative experience!</p>
                                    <a href="https://globaldevslam.com/" className="btn rey-btn mt-3">GITEX</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-5 mt-lg-0">
                            <div className="potoaboutwrap">
                                <img src={sideabout} alt="potoabout" className="w-100 d-block g5"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="gitex-head-outer">
                    <h3 className="mb-1 gitex-head mb-2">Introduction to Southville Solutions</h3>
                    <p className="subhead-offers text-center ">Southville Solutions offers opportunities and innovative
                        solutions for businesses.<br/>
                        We provide groundbreaking recruitment and digital transformation services.</p>
                </div>
                <div id="intro-south">
                </div>
                <div className="giTex-container mx-auto">
                    <div className="south-card">
                        <div className="south-card-image">
                            <img
                                src={requitment}/>
                        </div>
                        <div className="card-text">
                            <h2 className="card-title">Our Recruitment Approach</h2>
                            <p className="south-card-body">We have a revolutionary recruitment approach that connects
                                businesses with pre-vetted, talented candidates.
                                Our focus is on ensuring a perfect fit for the organization.</p>
                        </div>
                    </div>
                    <div className="south-card">
                        <div className="south-card-image">
                            <img
                                src={Dtransformation}/>
                        </div>
                        <div className="card-text">
                            <h2 className="card-title">Digital Transformation Services</h2>
                            <p className="south-card-body">
                                Specialized in custom software development for web and mobile apps.
                                Our services are guided by expert consultancy and backed by rigorous Software Quality
                                Assurance.
                            </p>
                        </div>
                    </div>
                    <div className="south-card">
                        <div className="south-card-image">
                            <img
                                src={officeimg}/>
                        </div>
                        <div className="card-text">
                            <h2 className="card-title">Seamless Back-Office Solution</h2>
                            <p className="south-card-body">
                                We offer seamless back-office solutions, handling functions like data management and
                                accounting support.
                                This allows businesses to concentrate on their core activities.
                            </p>
                        </div>
                    </div>
                    <div className="south-card">
                        <div className="south-card-image">
                            <img
                                src={overcoming}/>
                        </div>
                        <div className="card-text">
                            <h2 className="card-title">Navigating Location & Time Zones</h2>
                            <p className="south-card-body">
                                We provide access to top talent in Asia, helping businesses overcome location barriers.
                                Our services also address time zone challenges effectively.
                            </p>
                        </div>
                    </div>
                    <div className="south-card">
                        <div className="south-card-image">
                            <img
                                src={Collaboration}/>
                        </div>
                        <div className="card-text">
                            <h2 className="card-title">Collaboration at GITEX</h2>
                            <p className="south-card-body">
                                We invite collaboration at the GITEX event to explore our services.
                                We emphasize our role in helping businesses thrive in a competitive world.
                            </p>
                        </div>
                    </div>
                    <div className="south-card">
                        <div className="south-card-image">
                            <img
                                src={Aimingsuccess} className="whatboth"/>
                        </div>
                        <div className="card-text">
                            <h2 className="card-title">Aiming for Remarkable Success</h2>
                            <p className="south-card-body">
                                Our aim is to empower businesses to succeed in the evolving tech landscape.
                                Through collaboration with Southville Solutions, remarkable success is achievable.
                            </p>
                        </div>
                    </div>

                </div>

            </section>
            <div className="giTex-container mx-auto mt-5 mb-5">
                <div className="d-flex justify-content-center row">
                    <div className="col-md-12">
                        <div id="carousel-1" className="carousel slide" data-ride="carousel">
                            <div className="">
                                <div className="carousel-item active">
                                    <div className="south-explain">
                                        <div className="row ">
                                            <div className="col-md-5 d-none d-xl-block"><img
                                                className="img-fluid profile" src={bannerside} height="300"/></div>
                                            <div className="col-xl-7 sm:col-sm-12">
                                                <div className="bg-white south-explain-inner">
                                                    <h3 className="text-justify south-explain-h">
                                                        What Booth H26- P04 has for you at GITEX?</h3>
                                                    <p className="south-explain-p mb-2">At GITEX, Southville Solutions
                                                        will showcase its revolutionary approach to recruitment,
                                                        offering hassle-free and efficient processes to businesses
                                                        worldwide.
                                                        Our team will present our expertise in connecting clients with
                                                        pre-vetted, talented candidates, regardless of their
                                                        backgrounds, ensuring a perfect fit for their organizations.</p>
                                                    <p className="south-explain-p mb-2">Our booth will feature Asia's
                                                        top talent, ready to collaborate seamlessly, overcoming location
                                                        barriers and time zones, catering to businesses of all sizes.
                                                        We'll highlight how our services can streamline hiring, saving
                                                        up to 80% of time and effort,
                                                        providing clients with remote resources while guaranteeing
                                                        excellent communication skills through our rigorous vetting
                                                        process.</p>
                                                    <p className="south-explain-p mb-2">
                                                        Beyond recruitment, Southville Solutions will present its
                                                        specialized digital transformation services. Visitors can
                                                        explore our cutting-edge custom software development for web
                                                        and mobile apps, powered by the latest technologies.
                                                        Our consultancy expertise and Software Quality Assurance will be
                                                        showcased, ensuring bug-free applications and expert advice to
                                                        drive business success.
                                                    </p>
                                                    <p className="south-explain-p mb-2">
                                                        In addition, our comprehensive back office solutions tailored
                                                        for the software industry will be on display. GITEX attendees
                                                        can learn how we handle non-core functions,
                                                        such as data management and accounting support, freeing up
                                                        clients to focus on their core business activities.
                                                    </p>
                                                    <p className="south-explain-p mb-2">
                                                        Visitors to our booth will experience first-hand how Southville
                                                        Solutions empowers clients to thrive in a competitive world
                                                        through seamless, top-notch services. We invite everyone to
                                                        unlock growth possibilities by partnering with us, embracing
                                                        innovation, and achieving success in the rapidly evolving tech
                                                        landscape.
                                                        Join us at GITEX to explore the difference our services can make
                                                        for your organization!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="giTex-container mx-auto">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 opt-sm-0">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-6">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 mt-4 pt-2">
                                        <div className="card work-desk rounded border-0 shadow-lg overflow-hidden">
                                            <img src={burgkhalifa} className="img-fluid" alt="Image"/>
                                            <div className="img-overlay bg-dark"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-6">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card work-desk rounded border-0 shadow-lg overflow-hidden">
                                            <img src={khalifa2} className="img-fluid" alt="Image"/>
                                            <div className="img-overlay bg-dark"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 mt-4 pt-2">
                                        <div className="card work-desk rounded border-0 shadow-lg overflow-hidden">
                                            <img src={khalifadown} className="img-fluid" alt="Image"/>
                                            <div className="img-overlay bg-dark"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12 order-1 order-md-2">
                        <div className="section-title ml-lg-5">
                            <h5 className="text-custom font-weight-normal mb-3">Make sure you don’t miss visiting US
                                there, if you are not attending keep your self-posted through our social media:</h5>
                            <h4 className="title mb-4">
                                Our mission is to <br/>
                                make your life easier.
                            </h4>
                            <p className=" mb-0">
                                🚀 Unlock the Opportunity to Collaborate with Southville Solutions! 🤝
                                🔥 Don't miss out on our amazing services, fueling innovation and expertise. Experience
                                seamless solutions that empower your business! 🌐
                                <a href="#" onClick={handleToggle} className="ml-2">Click here to fill out the form and
                                    avail our services!</a>
                                {openModal && <FormModal open={openModal} openFun={handleToggle}/>}
                            </p>

                            <div className="row">
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media align-items-center rounded shadow p-3">
                                        <a href="https://instagram.com/southvillesolutions?igshid=MzRlODBiNWFlZA==" className="d-flex align-items-center">
                                            <img src={instagramicon} className="visiting-icons mb-0 me-2"  alt="Instagram"/>
                                            <h6 className="ml-3 mb-0"><a href="https://instagram.com/southvillesolutions?igshid=MzRlODBiNWFlZA==" className="text-dark">Intagram</a></h6>
                                        </a>
                                    </div>
                                </div>


                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media align-items-center rounded shadow p-3">
                                        <a href="https://www.linkedin.com/company/southville-solutions/mycompany/" className="d-flex align-items-center">
                                            <img src={linkedinicon} className="visiting-icons mb-0 me-2" alt="linkedin"/>
                                            <h6 className="ml-3 mb-0"><a href="https://www.linkedin.com/company/southville-solutions/mycompany/" className="text-dark">LinkedIn</a></h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4 pt-2">
                                    <div className="media align-items-center rounded shadow p-3">
                                        <a href="https://www.facebook.com/southvillesolutions/" className="d-flex align-items-center">
                                            <img src={facebookicon} className="visiting-icons mb-0 me-2"/>
                                            <h6 className=" mb-0"><a href="https://www.facebook.com/southvillesolutions/" className="text-dark">Facebook</a></h6>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default GiTex;