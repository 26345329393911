import React from 'react'
import {Col} from "react-bootstrap";
import './CompanyName.css'

const CompanyName = ({formData, setFormData, toggle}) => {
    return (
        <Col sm={12} md={12} className="d-flex">
            <form className="form-container animated-company">
                <div className="d-flex align-items-center gap-2 ">
                    <span className="heading-item">2)</span>
                    <h2 className="mb-0"> What your company name? <span>*</span></h2>
                </div>
                <p className="ms-4">Please add your company name</p>
                <div className="position-relative w-100">
                    <input type="text" className="w-100 input-form" placeholder="Type your answer here..."
                           value={formData.company}
                           onChange={(e) => setFormData({...formData, company: e.target.value})}/>
                    {toggle.checkName ?
                        <span className="position-absolute valid-error">Company name can't be empty.</span> : ""}
                </div>
            </form>
        </Col>
    )
}

export default CompanyName
