import React from 'react'
import './HeroSection.css'
import { Row, Col, Container } from 'react-bootstrap'
import hero_communication_img from '../assets/img/illustrations/3d6.png';

const HeroSection = () => {
    return (
        <>
            <section className='hs-cont hs-bg bg-light' id='hersec-id'>

                <Container>
                    <Row className='hs-row-main'>
                        <Col lg={6} className='hero-sec1'>
                            <div className='lh-sm mb-4 me-xl-5 fadeInDown animated1'>
                                <h1 className='display-2'>
                                    Hire Asia’s <span className="text-gradient gradient-1">Top Remote Talent</span> with Southville Solutions
                                </h1>
                            </div>
                            <div className='fs-5 mb-7 fadeInDown animated2'>
                                <p className='lead fs-23 lh-sm mb-7 pe-xxl-15 '>
                                    Indulge in a game-changer method of hiring pre-screened top remote talent. Our process is simple, efficient, and result driven.
                                </p>
                            </div>
                            <div className='mt-4 fadeInDown animated3'>
                                <a href={"#contact-us"} className='hs-btn-main btn border-0 '>
                                   Contact Us
                                </a>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='hs-img-wrapper imgfadeInDown imganimated'>
                                <img src={hero_communication_img} alt="hs-vector" width='100%' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default HeroSection