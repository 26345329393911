import axios from "axios";

const webHook = process.env.REACT_APP_SLACK_WEBHOOK_URL;
export default async function postModalData(formData, formHire, companyStack, range, skill, checkIsQr) {
    let items = ""
    for (const [key, value] of Object.entries(skill)) {
        if (value !== "" && value !== false)
            items += value + "\n";

    }


    const jobType = formHire.onSite || formHire.remote;
    const companyStacks = companyStack.backEnd || companyStack.frontEnd || companyStack.fullStack || companyStack.devOps
        || companyStack.dataEngineer || companyStack.mobileDeveloper || companyStack.productManager || companyStack.technicalLead || companyStack.otherStack;
    const software = range.one || range.two || range.three || range.four || range.five;
    const checker = !!checkIsQr;

    try {
        const data = {
            "text": `Hey, your Talent Request Form typeform got a new response. \n\n 1) What is your name? \n ${formData.name} \n\n 2) What is your company name? \n ${formData.company} \n\n 3) What is your company email? \n ${formData.companyEmail} 
            \n\n 4)  What type of hire do you need ? \n ${jobType} \n\n 5) What is the category of the role? \n  ${companyStacks}
            \n\n 6) How many software engineers are you looking to hire? \n ${software} 
            \n\n 7)  What skills should they have? \n ${items} \n\n 8) Anything else you want to tell us? \n ${formData.anything} 
            \n\n 8) Did the user fill form with Qr Code? \n ${checker}`
        }
        let res = await axios.post(webHook, JSON.stringify(data), {
            withCredentials: false,
            transformRequest: [(data, headers) => {
                delete headers.post["Content-Type"]
                return data
            }]
        })
        localStorage.setItem("qrForm", 1);
    } catch (error) {
    }
}