import React, { useState } from 'react'
import './Community.css'
import { Row, Container, Col } from 'react-bootstrap'
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
const Community = () => {
    const [active, setActive] = useState(true);
    return (
        <>
            <section className='coms-main-bg hs-cont bg-light'>
                <Container>
                    <div className='com-inner-sec'>
                        <Row>
                            <div className=' mt-4'>
                                <div className='text-center'>
                                    <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">Join Our Community</h2>
                                    <h3 className=' com-sec-head display-4' >We are trusted by over 100+ clients. <br/> Join them now and grow your business.</h3>
                                </div>

                            </div>
                        </Row>
                        <Row className="com-sec-head  d-flex align-items-center counter-wrapper mt-4">
                            <Col md={4} className="text-center">
                                <VisibilitySensor partialVisibility active={active}>
                                    {({ isVisible }) => (
                                        <>
                                            {
                                                isVisible ?
                                                    <h3 className="display-2 text-primary">
                                                        <CountUp end={4000} duration={2} onEnd={() => setActive(false)}>
                                                        </CountUp>+
                                                    </h3>
                                                    :
                                                    <h3 className="display-2 text-primary">0+</h3>
                                            }
                                        </>
                                    )}
                                </VisibilitySensor>
                                <p>Sourced Talent</p>
                            </Col>
                            <Col md={4} className="text-center">
                                <VisibilitySensor partialVisibility active={active}>
                                    {({ isVisible }) => (
                                        <>
                                            {
                                                isVisible ?
                                                    <h3 className="display-2 text-primary">
                                                        <CountUp end={2500} duration={2} onEnd={() => setActive(false)}>
                                                        </CountUp>+
                                                    </h3>
                                                    :
                                                    <h3 className="display-2 text-primary">0</h3>
                                            }
                                        </>
                                    )}
                                </VisibilitySensor>
                                <p>Vetted Talent</p>
                            </Col>
                            <Col md={4} className="text-center">
                                <VisibilitySensor partialVisibility active={active}>
                                    {({ isVisible }) => (
                                        <>
                                            {
                                                isVisible ?
                                                    <h3 className="display-2 text-primary">
                                                        <CountUp end={10} duration={2} onEnd={() => setActive(false)}>
                                                        </CountUp>+
                                                    </h3>
                                                    :
                                                    <h3 className="display-2 text-primary">0</h3>
                                            }
                                        </>
                                    )}
                                </VisibilitySensor>
                                <p>Talent from Counties</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Community