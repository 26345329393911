import React from 'react'
import {Col} from "react-bootstrap";

const Anything = ({formData, setFormData}) => {
  return (
      <Col sm={12} md={12} className="d-flex">
          <form className="form-container animated-name ">
              <div className="d-flex align-items-center gap-2 ">
                  <span className="heading-item">8)</span>
                  <h2 className="mb-0"> Anything else you want to tell us?</h2>
              </div>
                  <p className="ms-4">Feel free to tell us about your company or anything else here</p>
              <input type="text" className="w-100 input-form" placeholder="Type your answer here..."
                     value={formData.anything} onChange={(e) => setFormData({...formData, anything: e.target.value})}/>
          </form>
      </Col>
  )
}

export default Anything
