import React,{useEffect} from 'react'
import './ScrolltoTop.css'

const ScrolltoTop = () => {
    let progress = 0

    useEffect(() => {
        calculateProgress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress])

    const calculateProgress = () => {
        const progressWrap = document.querySelector('.progress-wrap');
    const progressPath = document.querySelector('.progress-wrap path');
    const pathLength = progressPath.getTotalLength();
    const offset = 50;
    if(progressWrap != null) {
        progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
        progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
        progressPath.style.strokeDashoffset = pathLength;
        progressPath.getBoundingClientRect();
        progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';
        window.addEventListener("scroll", function(event) {
        const scroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        progress = pathLength - (scroll * pathLength / height);
        progressPath.style.strokeDashoffset = progress;
        var scrollElementPos = document.body.scrollTop || document.documentElement.scrollTop;
        if(scrollElementPos >= offset) {
            progressWrap.classList.add("active-progress")
        } else {
            progressWrap.classList.remove("active-progress")
        }
        });
        progressWrap.addEventListener('click', function(e) {
        e.preventDefault();
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        });
    }

    }

    return (
        <>
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path className='efgh' d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
                </svg>
            </div>
        </>
    )
}

export default ScrolltoTop
