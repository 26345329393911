import React from 'react'
import './Client.css'
import { Row, Col, Container } from 'react-bootstrap'
import z1 from '../assets/img/brands/z1.png'
import z2 from '../assets/img/brands/z2.png'
import z3 from '../assets/img/brands/z3.png'
import z4 from '../assets/img/brands/z4.png'
import z5 from '../assets/img/brands/z5.png'
import z6 from '../assets/img/brands/z6.png'
import z7 from '../assets/img/brands/z7.png'
import z8 from '../assets/img/brands/z8.png'

const Client = () => {
    return (
        <>
            <section id="clients">
                <Container>
                    <div className='client-inner-sec'>
                        <Row>
                            <Col md={4} className="col-lg-4 mt-lg-2">
                                <h2 className="fs-15 text-gradient gradient-1 mb-3">OUR CLIENTS</h2>
                                <h3 className="display-4 mb-3 client-main-para">Trusted by over 100+ clients</h3>
                                <p className="lead fs-lg mb-0 client-main-para">We bring solutions to make life easier for our customers.</p>
                            </Col>                          
                            <Col md={8} className='text-center'>
                                <div className='row'>
                                    <div className='col-md-3 col-6  my-4'>
                                        <img className='client-z-img' src={z1} alt="Ozerfix" />
                                    </div>
                                    <div className='col-md-3 col-6  my-4'>
                                        <img className='client-z-img' src={z2} alt="Pizza House" />
                                    </div>
                                    <div className='col-md-3 col-6  my-4'>
                                        <img className='client-z-img' src={z3} alt="Eco City" />
                                    </div>
                                    <div className='col-md-3 col-6  my-4'>
                                        <img className='client-z-img' src={z4} alt="Great Logo" />
                                    </div>
                                    <div className='col-md-3 col-6  my-4'>
                                        <img className='client-z-img' src={z5} alt="Astro Lama" />
                                    </div>
                                    <div className='col-md-3 col-6  my-4'>
                                        <img className='client-z-img' src={z6} alt="Hidden Valley" />
                                    </div>
                                    <div className='col-md-3 col-6  my-4'>
                                        <img className='client-z-img' src={z7} alt="Olivi" />
                                    </div>
                                    <div className='col-md-3 col-6  my-4'>
                                        <img className='client-z-img' src={z8} alt="Alagar Investment" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Client