import React, {useEffect, useRef, useState} from "react";
import {Row, Col, Container, Form, Button} from "react-bootstrap";
import "./Contact.css";
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";

const Contact1 = () => {
    const [info, setInfo] = useState({
        name: "",
        email: "",
        message: "",
    });
    const reRef = useRef()
    const handleChange = (e) => {
        const {name, value} = e.target;
        setInfo({
            ...info,
            [name]: value,
        });
    };
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if(validated){
            axios.post("https://southville-dashboard.herokuapp.com/api/v1/contact_us", {
                    info
                },
                {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                    },
                }).then(res=> {setValidated(false)
                setInfo({name:"" , email: "" , message: ""})
                // const form = event.currentTarget;
                // event.stopPropagation();
                toast.success(res?.data?.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }).catch(error=>toast.error(error, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }))
        }

    } , [validated])
    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = await reRef.current.executeAsync();
        reRef.current.reset()
        if(token){
            setValidated(true);

        }



    };

    return (
        <>
            <section className="contact1-main">
                <Container>
                    <Row>
                        <Col lg={8} className="contact-sec1 d-md-block">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group
                                        className="col-md-6"
                                        md="4"
                                        controlId="validationCustom01"
                                    >
                                        <div className="form-floating mb-4">
                                            <Form.Control required type="text" placeholder="name" onChange={handleChange}
                                                          value={info.name}
                                                          name ="name"/>
                                            <Form.Control.Feedback type="invalid" className="mx-2">
                                                Please enter your first name.
                                            </Form.Control.Feedback>
                                            <Form.Label>Name *</Form.Label>
                                        </div>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        className="col-md-6"
                                        md="4"
                                        controlId="validationCustom01"
                                    >
                                        <div className="form-floating mb-4">
                                            <Form.Control
                                                required
                                                pattern=".+@gmail\.com"
                                                type="email"
                                                placeholder="Email"
                                                onChange={handleChange}
                                                value={info.email}
                                                name ="email"
                                            />
                                            <Form.Control.Feedback type="invalid" className="mx-2">
                                                Please provide a valid email address.
                                            </Form.Control.Feedback>
                                            <Form.Label>Email *</Form.Label>
                                        </div>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Form.Group
                                    className="col-md-12"
                                    md="4"
                                    controlId="validationCustom01"
                                >
                                    <div className="form-floating mb-4">
                    <textarea
                        className="contact-textarea form-control"
                        onChange={handleChange}
                        value={info.message}
                        id="form_message"
                        name="message"
                        placeholder="Your message"
                        required
                    ></textarea>
                                        <Form.Control.Feedback type="invalid" className="mx-2">
                                            Please enter your messsage.
                                        </Form.Control.Feedback>
                                        <Form.Label>Message *</Form.Label>
                                    </div>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-12"
                                    md="4"
                                    controlId="validationCustom01"
                                >
                                    {/*<div className="form-check mb-4">*/}
                                    {/*  <Form.Check*/}
                                    {/*    required*/}
                                    {/*    feedback="You must agree before submitting."*/}
                                    {/*    feedbackType="invalid"*/}
                                    {/*  />*/}
                                    {/*  <Form.Label className="form-check-label">*/}
                                    {/*    {" "}*/}
                                    {/*    I agree to{" "}*/}
                                    {/*    <a className="text-decoration-none" href="#/">*/}
                                    {/*      terms and policy*/}
                                    {/*    </a>*/}
                                    {/*    .{" "}*/}
                                    {/*  </Form.Label>*/}
                                    {/*</div>*/}
                                </Form.Group>
                                <Form.Group
                                    className="col-md-12"
                                    md="4"
                                    controlId="validationCustom01"
                                >
                                    <Button style={{
                                        'backgroundImage': "linear-gradient(120deg, #d63384 0%, #43c1b9 100%)",
                                        border: "#43c1b9"
                                    }}
                                            type="nosubmit"
                                            className="btn btn-primary rounded-pill mb-3 contact-send-btn"
                                    >
                                        Send message
                                    </Button>
                                </Form.Group>
                                <p className="text-muted">
                                    <strong>*</strong> These fields are required.
                                </p>
                            </Form>
                        </Col>

                        <Col lg={4} id="contact-us">
                            <div className="contact-detail-sec">
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-primary fs-28 me-4 mt-n1">
                                            {" "}
                                            <i className="uil uil-location-pin-alt"></i>{" "}
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">Address</h5>
                                        <address className="contact-address">
                                            85 Service Rd, Block R1 Phase 2 Johar Town, Lahore, Punjab 54000, Pakistan
                                        </address>
                                    </div>
                                </div>
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-primary fs-28 me-4 mt-n1">
                                            {" "}
                                            <i className="uil uil-phone-volume"></i>{" "}
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">Phone</h5>
                                        <p>
                                            (+92) 309 000945 <br/>
                                            (+92) 325 8188073
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-primary fs-28 me-4 mt-n1">
                                            {" "}
                                            <i className="uil uil-envelope"></i>{" "}
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">E-mail</h5>
                                        <p className="mb-0">
                                            <a
                                                href="mailto:info@southvillesol.com"
                                                className="text-body text-decoration-none"
                                            >
                                                info@southvillesol.com
                                            </a>
                                        </p>
                                        <p>
                                            <a
                                                href="mailto:hr@southvillesol.com"
                                                className="text-body text-decoration-none"
                                            >
                                                hr@southvillesol.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/*<ReCAPTCHA*/}
                {/*    sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}*/}
                {/*    size="invisible"*/}
                {/*    badge="bottomleft"*/}
                {/*    ref = {reRef} />*/}
                <ToastContainer />
            </section>
        </>
    );
};

export default Contact1;
