import React from 'react'
import './Process.css'
import {Container,Row,Col } from 'react-bootstrap'
const Process = () => {
    return (
        <>
            <section id="process" className='process-main'>
                <Container>
                    <div>
                        <h2 className="display-4 mb-2">Simple Steps to Hire Top Talent</h2>
                        <p className="lead fs-lg mb-3">We make your hiring
                            <span className="underline px-1">stress-free</span>
                            for you to have the perfect control.</p>
                    </div>
                    <div className='mt-4'>
                        <Row className="process-wrapper line">
                            <Col md={6} lg={3} className="process-col">
                                <span className="icon btn btn-circle btn-lg btn-soft-primary disabled mb-4">
                                    <span className="number">01</span>
                                </span>
                                <h4 className="mb-1">Share Job Requirements</h4>
                                <p className="mb-2 ">Our Recruitment Team will collaboratively work with you to gather all the necessary requirements about the Job.</p>
                            </Col>
                            <Col md={6} lg={3} className="process-col">
                                <span className="icon btn btn-circle btn-lg btn-primary disabled mb-4">
                                    <span className="number">02</span>
                                </span>
                                <h4 className="mb-1">Select Profiles from Pool</h4>
                                <p className="mb-2 ">Our team will share the relevant profiles from the pool with you so that you can shortlist candidates to proceed.</p>
                            </Col>
                            <Col md={6} lg={3} className="process-col">
                                <span className="icon btn btn-circle btn-lg btn-soft-primary disabled mb-4">
                                    <span className="number">03</span>
                                </span>
                                <h4 className="mb-1">Interview Talent</h4>
                                <p className="mb-2 ">Our team will help you in scheduling the interviews with the selected profiles to evaluate Technical skills and cultural fit. </p>
                            </Col>
                            <Col md={6} lg={3} className="process-col">
                                <span className="icon btn btn-circle btn-lg btn-soft-primary disabled mb-4">
                                    <span className="number">04</span>
                                </span>
                                <h4 className="mb-1">Hire the best Fit</h4>
                                <p className="mb-2 ">
                                    Our team will assist you in end to end Onboarding process as per your organizational manners.</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Process