import React from 'react'
import './Control.css'
import { Row, Col, Container } from 'react-bootstrap'
import solutions from '../assets/img/illustrations/3d2.png'
const Control = () => {
    return (
        <>
            <section className='control-sec-main bg-light'>
                <Container>
                    <div>
                        <Row>
                            <Col lg={6}>
                                <div className='control-img'>
                                    <img src={solutions} alt="cs-vector" width='100%' />
                                </div>
                            </Col>
                            <Col lg={6} className='mt-2 mb-5 control-sec-2'>
                                <div>
                                    <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">Have Perfect Control</h2>
                                    <h3 className='display-4 mb-4'>What makes us Special? </h3>
                                    <p>Our services are flexible and mission-driven. Our team can help you find the right candidate for the job. You don’t have to waste time on creating job posts and reviewing resumes. We can provide access to best match who can work with you.</p>
                                </div>
                                <div className='my-4 row '>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Equal Values and Ethics</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Up-to-date IP Protection</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Top-Notch Talent Pool</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Affordable hourly rates</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Managing Equipments & Benefits</p>
                                    </div>
                                    <div className='col-xl-6 d-flex '>
                                        <span className='control-icon text-center px-md-2 me-2'>
                                            <i className="fa fa-check control-fa-check"></i>
                                        </span>
                                        <p>Hassle-free hiring and payroll</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Control
