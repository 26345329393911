import {Col} from "react-bootstrap";
import './CompanyEmail.css'
import React from "react";


const CompanyEmail = ({formData, setFormData, toggle}) => {

    return (
        <Col sm={12} md={12} className="d-flex">
            <form className="form-container animated-email">
                <div className="d-flex align-items-center justify-content-center gap-2 ">
                    <span className="heading-item">3)</span>
                    <h2 className="mb-0">What's your company email? <span>*</span></h2>
                </div>
                <p className="ms-4">Please add your company email</p>
                <div className="w-100 position-relative">
                    <input type="email" required={true} className="w-100 input-form"
                           placeholder="Type your answer here..."
                           value={formData.companyEmail} onChange={(e) => setFormData({
                        ...formData,
                        companyEmail: e.target.value
                    })}/>
                    {toggle.checkName ? <span className="position-absolute valid-error">Email can't be empty.</span> : ""}
                    {toggle.checkEmail ? <span className="position-absolute valid-error">Invalid email.</span> : ""}

                </div>
            </form>
        </Col>
    )
}

export default CompanyEmail
