import React from 'react'
import Logo from "../../../assets/img/logo.png"
import "./Start.css"

const Start = () => {
    return (
        <div className="animated-start d-flex align-items-center justify-content-center flex-column w-100 gap-5 ">
            <img src={Logo} alt="logo image"/>
            <p className="fs-6 text-dark">Need amazing talent or advice? Fill out this form.</p>
        </div>
    )
}

export default Start
