import React, {useEffect} from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import {Container} from 'react-bootstrap'
import './Navbarmobile.css'
import Mobilelogo from '../assets/img/white-logo.png'
import {Link as ReactLink, useLocation} from "react-router-dom";
import {Link} from "react-scroll";

const NavbarMobile = ({isOpen, setOpen, menuref, onOpenModal}) => {
    const {pathname} = useLocation();
    const closeNavbar = () => {
        setOpen(!isOpen);
    }

    const handleToggle = () => {
        onOpenModal();
        setOpen(!isOpen);

    }
    useEffect(() => {
        Aos.init({duration: 500});
    }, []);
    return (
        <>
            <section>
                <div ref={menuref} className='navmobile-main p-2' data-aos="fade-right">
                    <Container>
                        <div className='mt-3 p-2 fs-2 d-flex justify-content-between'>
                            <div><a href="/"><img src={Mobilelogo} alt="navbar logo" width="150px"/></a></div>
                            <div onClick={closeNavbar}><i className="fa fa-times-circle" aria-hidden="true"></i></div>
                        </div>
                        <div>
                            {
                                pathname === "/" ?
                                    <ul className='list-unstyled p-2'>
                                        <li className='mt-3'><Link onClick={closeNavbar}
                                                                   className="navmobile-links text-decoration-none"
                                                                   activeClass="active" spy={true} to="hersec-id"
                                                                   offset={-100}>
                                            Home
                                        </Link>
                                        </li>
                                        <li className='mt-3'>
                                            <Link onClick={closeNavbar} className="navmobile-links text-decoration-none"
                                                  activeClass="active" spy={true} to="features"
                                                  offset={-100}>
                                                Features
                                            </Link>
                                        </li>
                                        <li className='mt-3'>
                                            <Link onClick={closeNavbar} className="navmobile-links text-decoration-none"
                                                  activeClass="active" spy={true} to="proud-id"
                                                  offset={-100}>
                                                Process
                                            </Link>
                                        </li>
                                        <li className='mt-3'>
                                            <Link onClick={closeNavbar} className="navmobile-links text-decoration-none"
                                                  activeClass="active" spy={true} to="about-id"
                                                  offset={-100}>
                                                About Us
                                            </Link>
                                        </li>
                                        <li className='mt-3'>
                                            <Link onClick={closeNavbar} className="navmobile-links text-decoration-none"
                                                  activeClass="active" spy={true} to="footer-id"
                                                  offset={-100}>
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li className='mt-3'>
                                            <ReactLink onClick={closeNavbar}
                                                       className="navmobile-links text-decoration-none"
                                                       activeClass="active" spy={true} to="/gitex">
                                                GiTex Dubai
                                            </ReactLink>
                                        </li>
                                        <li className='mt-3'>
                                            <Link onClick={handleToggle}
                                                  className="navmobile-links text-decoration-none"
                                                  activeClass="active">
                                                Hire Top Talent
                                            </Link>
                                        </li>
                                    </ul>
                                    :
                                    <ul className='list-unstyled p-2'>
                                        <li className='mt-3'>
                                            <ReactLink to="/" onClick={closeNavbar}
                                                  className="navmobile-links text-decoration-none"
                                                  activeClass="active" spy={true}>
                                            Home
                                        </ReactLink>
                                        </li>

                                        <li className='mt-3'>
                                            <Link onClick={closeNavbar} className="navmobile-links text-decoration-none"
                                                  activeClass="active" spy={true} to="footer-id"
                                                  offset={-100}>
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li className='mt-3'>
                                            <ReactLink onClick={closeNavbar}
                                                       className="navmobile-links text-decoration-none"
                                                       activeClass="active" spy={true} to="/gitex">
                                                Gitex Dubai
                                            </ReactLink>
                                        </li>
                                        <li className='mt-3'>
                                            <Link onClick={handleToggle}
                                                  className="navmobile-links text-decoration-none"
                                                  activeClass="active">
                                                Hire Top Talent
                                            </Link>
                                        </li>
                                    </ul>
                            }
                        </div>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default NavbarMobile