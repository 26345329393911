import React, { useState } from 'react'
import './Proud.css'
import { Row, Col, Container } from 'react-bootstrap'
import target from '../assets/img/icons/solid/target.svg'
import barchart from '../assets/img/icons/solid/bar-chart.svg'
import employees from '../assets/img/icons/solid/employees.svg'
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";


// import Countup from './CountupReact'
function Proud() {
    const [active, setActive] = useState(true);

    return (
        <>
            <section className='ps-cont ps-bg prod-main-sec bg-light' id='proud-id'>
                <Container>
                    <div className='py-5'>
                        <Row className='d-flex text-center'>
                            <Col md={12} lg={3} className='mt-4 ps-col-1' >
                                <h3 className='display-4 '>We are proud of our works</h3>
                                <p className="lead fs-lg ps-head-para">We bring solutions to make life easier for our customers.</p>
                            </Col>
                            <Col md={4} lg={3} className=' mt-4  '>
                                <div className="p-logo">
                                    <img src={target} alt="" width='50' />
                                </div>
                                <div className='mt-2'>
                                    <VisibilitySensor partialVisibility active={active}>
                                        {({ isVisible }) => (
                                            <>
                                                {
                                                    isVisible ?
                                                        <h3 className='display-4'>
                                                            <CountUp end={600} duration={2} onEnd={() => setActive(false)}>
                                                            </CountUp>+
                                                        </h3>
                                                        :
                                                        <h3 className="counter_numbers">0+</h3>

                                                }
                                            </>
                                        )}
                                    </VisibilitySensor>
                                    <p className='mt-2 m-0'>Assigned Talent</p>
                                </div>
                            </Col>
                            <Col md={4} lg={3} className=' mt-4 '>
                                <div className="p-logo">
                                    <img src={barchart} alt="" width='50' />
                                </div>
                                <div className='mt-2'>

                                    <VisibilitySensor partialVisibility active={active}>
                                        {({ isVisible }) => (
                                            <>
                                                {
                                                    isVisible ?
                                                    <h3 className='display-4'>
                                                            <CountUp end={4} duration={2} onEnd={() => setActive(false)}>
                                                            </CountUp>
                                                            x
                                                        </h3>
                                                        :
                                                        <h3 className="counter_numbers display-4'">0x</h3>

                                                }
                                            </>
                                        )}
                                    </VisibilitySensor>
                                    <p className='mt-2 m-0'>Talent Pool Growth</p>
                                </div>
                            </Col>
                            <Col md={4} lg={3} className=' mt-4 '>
                                <div className="p-logo">
                                    <img src={employees} alt="" width='50' />
                                </div>
                                <div className='mt-2'>
                                    <VisibilitySensor partialVisibility active={active}>
                                        {({ isVisible }) => (
                                            <>
                                                {
                                                    isVisible ?
                                                        <h3 className='display-4'>
                                                            <CountUp end={97.7} duration={2} decimal={"."} decimals={1} onEnd={() => setActive(false)}>
                                                            </CountUp>
                                                            %
                                                        </h3>
                                                        :
                                                        <h3 className="counter_numbers display-4'">0%</h3>

                                                }
                                            </>
                                        )}
                                    </VisibilitySensor>
                                    <p className='mt-2 m-0'>Customer Satisfaction</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col md={6} xl={3} className="scollar">
                                <div className='p-card-main p-4 card-border-bottom ps-shadow-lg mt-3'>
                                    <div className='p-card-content'>
                                        <span>
                                            “Southville Solutions is an excellent technology partner. They are effective, responsive, helpful, and communicative. We have had an excellent experience over the past several years, and we would recommend them to everyone.”
                                        </span>
                                    </div>
                                    <div className="p-card-aurthor pt-5">
                                        <h5 className='fw-bold'>
                                            Raj Salhotra
                                        </h5>
                                    </div>
                                    <div className="p-card-postion">
                                        <span>Founder - Momentum Education</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} xl={3} className="scollar">
                                <div className='p-card-main p-4 card-border-bottom ps-shadow-lg mt-3'>
                                    <div className='p-card-content'>
                                        <span>
                                            “We were lucky to partner with Southville Solutions for the development work. This company actually listens and understands the needs and provides the effective solutions. The product that they delivered was exactly what was required. Highly recommended!”
                                        </span>
                                    </div>
                                    <div className="p-card-aurthor pt-5">
                                        <h5 className='fw-bold'>
                                            Usman Ahmed
                                        </h5>
                                    </div>
                                    <div className="p-card-postion">
                                        <span>Founder - Gas2Go</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} xl={3} className="scollar">
                                <div className='p-card-main p-4 card-border-bottom ps-shadow-lg mt-3'>
                                    <div className='p-card-content'>
                                        <span>
                                            “Southville Solutions is a fantastic development hub. They are prompt, responsive, reliable, and most importantly exceptional communicators. They routinely asked the right questions in order to ensure that the code to be developed was well thought out, and responsive to our users’ needs. They are quick to think on their feet and to execute solutions to challenges as they arrive, and will give their 100% to each client.”
                                        </span>
                                    </div>
                                    <div className="p-card-aurthor pt-5">
                                        <h5 className='fw-bold'>
                                            Shiroy Aspandiar
                                        </h5>
                                    </div>
                                    <div className="p-card-postion">
                                        <span>Founder - OneJump</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} xl={3} className="scollar">
                                <div className='p-card-main p-4 card-border-bottom ps-shadow-lg mt-3'>
                                    <div className='p-card-content'>
                                        <span>
                                            “First time working with Southville Solutions.  They executed the development of a SaaS website exactly as I had envisioned.   Their team is easy to communicate with, responsive, professional and a pleasure to work with.  I am very happy with the end product.”
                                        </span>
                                    </div>
                                    <div className="p-card-aurthor pt-5">
                                        <h5 className='fw-bold'>
                                            Jack St-Laurent
                                        </h5>
                                    </div>
                                    <div className="p-card-postion">
                                        <span>Founder - ShipOracle Inc</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Proud